@import "./variables.scss";

section.light {
  background-color: $mylab-light-gray;
}

.revenue {
  min-height: 50px;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}

section.white {
  background-color: $white;
  color: $black !important;
  & * {
    color: $black;
  }
}

.title,
.subtitle {
  clear: both !important;
}

section.white-milk {
  background-color: $white-ter;
  color: $black !important;
  & * {
    color: $black;
  }
}

button.slick-arrow {
  display: none !important;
}

img.quality-logo {
  max-height: 80px;
  margin: auto;
}

div.home-sqr-1 {
  height: 180px;
  max-height: 180px;
  display: contents;
}

.column.home-logo {
  padding-left: 50px;
  padding-right: 50px;
}

img.home-logo {
  max-height: 120px;
}

a.home-logo {
  min-height: 150px;
}

.white-stripe {
  min-height: 1px;
  max-height: 1px;
  min-width: 100%;
  background-color: $white;
}

p.home-sqr-1 {
  float: left;
  position: relative;
  top: -77px;
  left: -17px;
  //noinspection CssUnknownTarget
  display: block;
  min-height: 150px;
  max-width: 108px;
  min-width: 108px;
}

p.home-sqr-2 {
  float: right;
  position: relative;
  right: 0;
  top: -95px;
  //noinspection CssUnknownTarget
  display: block;
  min-height: 125px;
  max-width: 40px;
  min-width: 40px;
}

p.home-sqr-qualita {
  float: right;
  position: relative;
  right: -12px;
  top: 30px;
  //noinspection CssUnknownTarget
  background-size: cover;
  display: block;
  min-height: 100px;
  max-width: 66px;
  min-width: 66px;
}

p.home-sqr-quantita {
  float: left;
  position: relative;
  left: -12px;
  top: 30px;
  //noinspection CssUnknownTarget
  background-size: cover;
  display: block;
  min-height: 100px;
  max-width: 66px;
  min-width: 66px;
}

p.home-sqr-dosi {
  float: right;
  position: relative;
  right: -12px;
  top: 30px;
  //noinspection CssUnknownTarget
  background-size: cover;
  display: block;
  min-height: 100px;
  max-width: 66px;
  min-width: 66px;

  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH();
  -ms-filter: "FlipH";
}

img.home-3step {
  width: 60px;
  height: 60px;
  //noinspection CssUnknownTarget
  background-size: contain;
}

img.home-mockup {
  max-height: 400px;
  //noinspection CssUnknownTarget
  background-size: contain;
}

.text-70-perc {
  max-width: 70% !important;
  margin: auto;
}

.img-affiliate {
  max-height: 250px !important;
  margin: auto;
  margin-top: 40px;
  display: block;
}

.txt-affiliate {
  margin: auto !important;
  text-align: center;
  margin-top: 40px !important;
  max-width: 70%;
}

.privacy-policy {
  margin-top: $navmarginMobile;
}

// home hero
.home-hero {
  padding-top: $navmarginMobile;
  //noinspection CssUnknownTarget
  background-size: 100vw auto;

  & > div {
    padding-left: 17px;
    padding-right: 15px;
    background: linear-gradient(to bottom, rgba(5, 5, 5, 0.34), $black);
  }

  & .hero-body {
    padding-top: 56px;
  }
  & .title {
    max-width: 380px;
    margin: 20px auto 40px auto;
    clear: both;
  }
  & .title,
  & .subtitle {
    text-align: center;
    clear: both !important;
  }
  &.main-hero .subtitle {
    margin-top: -18px;
    font-weight: 300;
    max-width: 249px;
    margin: auto;
  }
  & .button {
    width: 100%;
  }
  & .button.is-primary {
    margin-top: 40px;
  }
}

.home-hero.chi-siamo {
  //noinspection CssUnknownTarget
  background-size: 100vw auto;
  margin: 0;
  & .title,
  & .subtitle {
    text-align: left;
    margin-left: 0px;
  }
}

.home-hero.come-funziona {
  //noinspection CssUnknownTarget
  background-size: 100vw auto;
  margin: 0;
  & .title {
    clear: both;
    margin: 20px 0px 15px 0px;
  }
  & h1.title {
    margin-bottom: 60px;
  }
  & .title,
  & .subtitle {
    text-align: left;
  }
  & .button {
    display: inline-block !important;
  }
}

ul.pt-list {
  & li {
    //noinspection CssUnknownTarget

    background-size: 24px 24px;
    background-position-x: 0;
    background-position-y: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    padding-left: 40px;
  }
  margin-bottom: 20px;
}

.home-hero.qualita-centered {
  //noinspection CssUnknownTarget
  background-size: 100vw auto;
  margin: auto;
  & .title,
  & .subtitle {
    text-align: center !important;
    margin: auto !important;
  }
}

.home-hero.qualita {
  //noinspection CssUnknownTarget
  background-size: 100vw auto;
  margin: 0;
  & .title,
  & .subtitle {
    text-align: left;
    margin-left: 0px;
  }
}

.home-hero.personal-trainer {
  //noinspection CssUnknownTarget
  // background-size: 100vw auto;
  margin: auto;
  & .title,
  & .subtitle {
    text-align: center;
    margin: 20px auto !important;
  }
}

.chi-siamo-steps {
  background-image: linear-gradient(167deg, #db2754, #981b3b);

  & .home-sqr-1 > * {
    margin-top: 40px;
  }
  & + section.ptb-80 {
    padding-top: 100px;
  }
}

.home-hero + .home-sqr-1 * {
  margin-top: -45px;
}

// home hero
.home-hero-2 {
  //noinspection CssUnknownTarget
  background: url("https://assets.mylabnutrition.net/images/packaging-mylab.png")
    no-repeat center center;
  background-size: contain;
  & .container {
    text-align: center;
  }
  & > div {
    padding-top: 80px;
    padding-bottom: 80px;
    background: linear-gradient(
      to bottom,
      rgba($mylab-color-light, 0.34),
      $mylab-color
    );
  }
}

@media (max-width: 325px) {
  p.home-sqr-1 {
    top: -120px;
  }

  p.home-sqr-2 {
    top: -135px;
  }

  h1.title {
    font-size: 30px;
  }

  h2.subtitle {
    font-size: 15px;
  }

  .home-hero {
    //padding-top: 10px;
    & .hero-foot {
      max-height: 10px;
    }
  }
}

@media (min-width: 326px) {
  @media (max-width: 360px) {
    p.home-sqr-1 {
      top: -46px;
    }

    p.home-sqr-2 {
      top: -60px;
    }

    h1.title {
      font-size: 30px;
    }

    h2.subtitle {
      font-size: 15px;
    }

    .home-hero {
      //padding-top: 30px;
      margin-bottom: 20px;
      & .hero-foot {
        max-height: 40px;
      }
    }
  }
}

@media (min-width: 361px) {
  @media (max-width: 375px) {
    p.home-sqr-1 {
      top: -33px;
    }

    p.home-sqr-2 {
      top: -45px;
    }

    h1.title {
      font-size: 30px;
    }

    h2.subtitle {
      font-size: 15px;
    }

    .home-hero {
      //padding-top: 30px;
      margin-bottom: 20px;
      & .hero-foot {
        max-height: 60px;
      }
    }
  }
}

@media (min-width: 376px) {
  @media (max-width: 415px) {
    p.home-sqr-1 {
      top: -33px;
    }

    p.home-sqr-2 {
      top: -45px;
    }

    .home-hero {
      margin-bottom: 20px;
      & .hero-foot {
        max-height: 80px;
      }
    }
  }
}

@media (min-width: 416px) {
  @media (max-width: 425px) {
    p.home-sqr-1 {
      top: -10px;
    }

    p.home-sqr-2 {
      top: -10px;
    }
  }
}

@media (min-width: 768px) {
  .navbar-brand .navbar-item:first-child {
    padding-left: 0px;
  }

  .navbar-end .navbar-item:last-child {
    padding-right: 0px;
  }

  .home-hero {
    & .title {
      font-size: 2.7rem;
      margin: 0px 0px 20px 0px;
    }
    & .title,
    & .subtitle {
      text-align: left;
    }
    & .subtitle {
      margin-top: -18px;
      max-width: 70% !important;
      margin: 0px !important;
    }
    & .button {
      width: 260px;
      display: block !important;
      max-width: 300px !important;
    }
    & .button.is-primary {
      margin-top: 20px;
    }
    & .button:not(.is-primary):last-child {
      border: 1px solid rgba(255, 255, 255, 0.7);
    }
    & .header-line {
      height: 2px;
      margin: 10px 0px 30px -20px;
      width: 200px;
      background: $mylab-color;
    }
    & .hero-body {
      padding-left: 0px;
      padding-right: 0px;
    }
    & .video-bg {
      border: 3px solid $mylab-color;
      position: relative;
      width: 90%;
      height: 320px;
      float: right;
      & .video {
        background: rgba(255, 255, 255, 0.4);
        position: absolute;
        width: 100%;
        height: 100%;
        top: -15px;
        left: -15px;
        border: 1px solid #bdbdbd;
      }
    }
  }

  .home-hero-2 {
    & .container {
      text-align: left;
    }
    & .title {
      max-width: 720px;
      font-size: 2rem;
    }
  }

  .home-hero.come-funziona {
    & .title {
      clear: both;
      margin: auto;
    }
    & h1.title {
      margin-bottom: 60px;
      text-align: center;
    }
    & h4.title {
      font-size: 1.8rem;
      margin-bottom: 15px;
    }
    & img.home-3step {
      width: 120px;
      height: 120px;
    }
  }

  .home-hero.personal-trainer {
    & .hero-body {
      padding-bottom: 80px;
    }
    & h1.title {
      max-width: 475px;
    }
    & h4.title {
      font-size: 1.3rem;
      margin-top: 80px !important;
      margin-bottom: 30px !important;
    }
    & .button {
      max-width: 320px !important;
    }
  }

  .mtestimonials .slick-slide {
    padding: 15px;
  }

  .mtestimonials h3.title {
    margin-top: 10px;
  }

  .mtestimonials h2.subtitle {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .mtestimonials h2.subtitle:last-child {
    margin-bottom: 10px;
    font-size: 14px;
  }

  p.home-sqr-qualita,
  p.home-sqr-quantita,
  p.home-sqr-dosi {
    float: right;
    width: 100px;
    height: 100px;
    max-width: 150px;
    max-height: 150px;
    position: static;
    opacity: 0.7;
  }

  .home-sqr-item {
    margin: 40px 0px;
    & .title {
      font-size: 2rem;
    }
  }

  .affiliate-form-section {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 60px;
    & h2.title {
      margin-top: 80px;
    }
    & h2.subtitle {
      font-size: 20px;
      max-width: 80%;
      margin-bottom: 40px;
    }
  }
}

@media (min-width: 1025px) {
  .home-hero {
    padding-top: $navmarginDesktop;
    // background: url("/images/atleta-trazione.jpg") no-repeat 0 0;
    background-size: 100vw auto;
  }
  .privacy-policy {
    margin-top: $navmarginDesktop;
  }
}
