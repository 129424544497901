@import "./variables.scss";

.mobile-navbar {
  min-height: 60px;
  background-color: #1d1d1f;

  & > div > *,
  & > * {
    min-height: 60px;
  }
  & > div {
    width: 100%;
  }
  & .navbar-item {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  & .navbar-brand > .navbar-item {
    padding-left: 16px;
  }

  & .navbar-end > .navbar-item {
    padding-top: 12px;
    &:last-child {
      padding-right: 11px;
    }
  }

  &.is-transparent {
    background: $mylab-gray;
    &.full-transparent {
      background: transparent;
    }
    & > * {
      background-color: rgba($mylab-gray, 0.5);
    }
  }

  &.is-home-navbar {
    background: transparent;
    &.full-transparent {
      //noinspection CssUnknownTarget
      background-size: cover;
    }
    & > * {
      background-color: rgba($mylab-gray, 0.5);
    }
  }
}

.sub-dropdown {
  position: absolute;
  top: 0px;
  right: 100%;
  background: white;
  min-width: 150px;
  display: none;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.dropdown-item {
  cursor: pointer;
}
.sub-dropdown-trigger:hover .sub-dropdown {
  display: block;
}
