@import "./variables.scss";

.min-height-140 {
  min-height: 200px !important;
  max-height: auto !important;
}

.m-a {
  margin: auto;
}
.mt--8 {
  margin-top: -8px !important;
}

.mt--5 {
  margin-top: -5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-68 {
  margin-top: 68px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-100 {
  margin-top: 80px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mtb-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.ml-25 {
  margin-left: 25px;
}
.ml-15 {
  margin-left: 15px;
}

.ml-6p {
  margin-left: 6%;
}

.mb-6p {
  margin-bottom: 6%;
}

.no-p {
  padding: 0 !important;
}

.no-pl {
  padding-left: 0 !important;
}
.no-pr {
  padding-right: 0 !important;
}

.no-pb {
  padding-bottom: 0 !important;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.no-p-lr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-p-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-r-2 {
  padding-right: 2px;
}

.p-l-2 {
  padding-left: 2px;
}

.no-m {
  margin: 0 !important;
}

.no-ml {
  margin-left: 0 !important;
}

.no-mb {
  margin-bottom: 0 !important;
}

.no-max-height {
  max-height: none !important;
}

.no-min-height {
  min-height: 0 !important;
}

.no-box-shadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1p {
  padding-top: 1.5% !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-64 {
  padding-top: 64px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-5 {
  padding-right: 5px !important;
}

.ptb-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ptb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ptb-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-10 {
  padding: 10px !important;
}
.pt-0 {
  padding-top: 0;
}

.pl-0 {
  padding-left: 0;
}

.pl-15 {
  padding-left: 15px;
}

.pr-1em {
  padding-right: 1em;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-24 {
  padding: 24px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-80 {
  padding-bottom: 80px;
}

.ta-c {
  text-align: center !important;
}

.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

.d-b {
  display: block !important;
}
.d-ib {
  display: inline-block;
}

.has-opacity-05 {
  opacity: 0.5;
}

.no-overflow-with-h-scroll {
  overflow: hidden;
  overflow-x: auto;
}

.is-fullwidth-block {
  width: 100% !important;
  display: block;
}

.btns-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 45px;
  padding: 0 15px 15px;
}

.is-question {
  margin-top: 25px;
  &:first-child {
    margin-top: 0;
  }
}

.desktop-full-height {
  @media screen and (min-width: 1024px) {
    height: 100% !important;
    min-height: 100% !important;
  }
}

// Images

.image {
  &.is-14x14 {
    width: 14px;
    height: 14px;
  }
  &.is-18x18 {
    width: 18px;
    height: 18px;
  }
}

.is-truncated {
  overflow: hidden;
  position: relative;
  max-height: 7.2em;
  padding-right: 1em;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0.3em;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
  }
}

.display-table-cell {
  display: table-cell;
}

.is-vertically-aligned {
  vertical-align: middle;
}

// BG

.has-no-bg {
  background: none;
}

// Normalize

.has-padding-3 {
  padding: 3%;
}

.justify-content-left {
  justify-content: left !important;
}

.is-vertical-centered {
  align-items: center;
}

.has-relative-position {
  position: relative;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}
