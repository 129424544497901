@import "./variables.scss";

.rc-slider-track {
  height: 4px !important;
  background-color: #00000033 !important;
}

.rc-slider-rail {
  background: #e9e9e996 !important;
}

.rc-slider-dot {
  height: 20px !important;
  width: 20px !important;
  border-color: $consulenza-background !important;
  bottom: -8px !important;
  margin-left: -8px !important;
  background: #454545 !important;
  &.rc-slider-dot-active {
    background-color: $mylab-color-light !important;
  }
}

.rc-slider-handle,
.rc-slider-handle:active {
  border-color: $consulenza-background !important;
  background-color: $mylab-color-light !important;
  box-shadow: none !important;
  margin-left: -12px !important;
  margin-top: -10px !important;
  width: 24px !important;
  height: 24px !important;
}

.rc-slider-step {
  background: #0000007d !important;
}

.rc-slider-dot.rc-slider-dot-active:first-child,
.rc-slider-dot.rc-slider-dot-active:nth-child(2) {
  background: #8bc34a !important;
}
