@import "./variables.scss";
.mln-box {
  background-color: #131314;
  border: solid 1px #db2754;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform .2s; /* Animation */

  padding: 16px;
  font-family: $family-header;
  text-transform: uppercase;
  font-size: 14px;

  flex-direction: column;

  & .check {
    display: none;
  }

  &.is-checked {
    background-color: $mylab-color;

    & .check {
      display: block;
      position: absolute;
      top: -8px;
      right: -8px;
      max-height: 24px;
      max-width: 24px;
    }
  }

  &:hover, &:active {
    transform: scale(1.05);
  }

  & > * {
    display: flex;
  }

  &.is-fullwidth {
    width: 100%;
  }
  & > div > img {
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
    height: 40px;
    margin: auto;
    display: block;
  }
}