@import "./variables.scss";

.dropdown-content {
  border-radius: 0;
  .dropdown-item {
    font-family: Oswald, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    text-align: right;
    &:hover {
      background-color: $mylab-color-light;
      color: $white;
    }
  }
}

.elements-dropdown {
  min-width: 300px !important;
}

.dropdown-menu {
  min-width: auto !important;

  &.align-right {
    left: inherit;
    right: 0px;
  }
}

.dropdown-menu-bis .dropdown-menu {
  position: relative;
  padding-top: 110 !important;
}

.dropdown-user .dropdown-menu {
  padding-top: 0 !important;
  top: 90% !important;
}

.orders-sort-dropdown {
  min-width: 120px !important;
}

.share-dropdown {
  & .dropdown-menu {
    padding-top: 0px;
  }
  & .dropdown-content {
    background-color: #424243;
    color: white;
    & a {
      color: white;
      padding-right: 15px;
    }
  }
}

.dropdown-icn {
  // float: right;
  // margin-left: 15px;
  // margin-top: 5px;
  position: absolute;
    right: 10px;
}
