@import "./variables.scss";

.mtestimonials .testimonials-image {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  margin: auto;
  background-color: $grey-light;
}

.mtestimonials .slick-dots li button:before {
  color: rgba(255, 255, 255, 0.3);
  font-size: 8px;
}

.mtestimonials .slick-dots li.slick-active button:before {
  color: $mylab-color-light !important;
}