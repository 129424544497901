.squares-bg-element {
  text-align: center;
  width: 100%;
  max-width: 290px;
  height: 300px;
  max-height: 230px;
  margin: 7% auto;
  & img {
    width: 145px;
    margin: 15%;
  }
}

.login {
  margin-top: 8px;
}

.left-header-button {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  margin-top: 1.5px;
}

.form-msg {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  & .msg-icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
  }
}

.summary-header .column:last-child {
  text-align: right;
}

.summary-items p span,
.summary-items h4 span,
.summary-items h3 span {
  float: right;
}

.summary-items h3 {
  border-top: 1px solid grey;
  margin-top: 20px;
  padding-top: 20px;
  margin-bottom: 30px;
}

.cart-result {
  position: relative;
  height: 100vh;
}

.cart-result h2 {
  max-width: 70%;
  margin: auto;
}

.cart-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.cart-icon-recap {
  .image {
    width: 24px;
    height: 24p;
  }
  p {
    font-size: 18px;
    margin-top: 5px;
  }
}

.discount {
  color: #981b3b;
  text-decoration: line-through;
}

@media (min-width: 768px) {
  .cart-container {
    padding: 60px 0px 80px 0px;
    h1.title {
      margin-bottom: 40px;
    }
    .notification {
      margin-bottom: 7px;
    }
    .button {
      width: 100% !important;
      max-width: none !important;
      &.has-max-width {
        max-width: 300px !important;
        margin: auto !important;
      }
    }
  }

  .cart-icon-recap {
    .image {
      width: 48px;
      height: 48px;
    }
  }

  .recipe-box {
    .card-header-title {
      padding: 20px 30px 5px 30px;
      font-size: 20px;
    }
    .card-content {
      padding: 0px 20px;
    }
    .card-actions {
      padding-bottom: 20px;
    }
  }

  #suggestion {
    h5.title {
      margin: 0px;
      font-size: 24px !important;
      padding: 20px 0px 30px 0px;
    }
    .formula-list-element {
      display: inline-block;
      border: 0px;
      background: #58585d !important;
      margin: 1%;
      width: 30%;
      box-shadow: 0px 2px 25px -10px black;
      -webkit-box-shadow: 0px 2px 25px -10px black;
      -moz-box-shadow: 0px 2px 25px -10px black;
      -ms-box-shadow: 0px 2px 25px -10px black;
      -o-box-shadow: 0px 2px 25px -10px black;
      transition: all ease 0.2s;
      -webkit-transition: all ease 0.2s;
      -moz-transition: all ease 0.2s;
      -ms-transition: all ease 0.2s;
      -o-transition: all ease 0.2s;
      &:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
      }
    }
    .star-container {
      margin: 0px;
    }
    &:hover {
      p {
        color: white !important;
      }
    }
  }
}
