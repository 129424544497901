@import "./variables.scss";

.button {
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border-radius: 0;
  border: none;
  max-height: none;
  padding-left: 20px;
  padding-right: 20px;
  word-wrap: break-word;
  background-color: $white;
  color: $mylab-gray;
  min-height: 36px;
  height: auto;

  &.width-285 {
    width: 285px  !important;
  }

  &:hover {
    background-color: $white-hover;
  }
  &.is-disabled,
  &[disabled] {
    opacity: 0.5;
  }

  &.is-primary {
    color: $white;
    background: $mylab-gradient;
    &:hover {
      background: $mylab-color;
    }
  }

  &.is-primary-dashboard {
    margin-bottom: 20px;
    color: $white;
    background: $mylab-gradient;
    &:hover {
      background: $mylab-color;
    }
    width: 300px !important;
    height: 110px;
  }

  &.is-transparent {
    color: white;
    background: transparent;

    &:hover {
      background: $transparent-hover;
    }

    &.is-bordered,
    &.is-outlined {
      border: solid 1px $white;
      &.is-primary {
        color: $mylab-color;
        border: solid 1px $mylab-color;
        &:hover {
          background: $transparent-primary-hover;
          border-color: $mylab-color;
        }
      }
      &.is-red {
        color: $mylab-color;
        border-color: $mylab-color;
      }
    }
  }

  &.is-text {
    font-family: Oswald;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: right;
    color: #db2754;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.level-item .control .button {
  height: 100%;
  border-left: 1px solid #c8c8c8;
}

.navbar-item {
  & button {
    background: transparent;
    color: white;
  }
}

.modal-button {
  margin-top: 0px !important;
  background-color: $mylab-color;
  width: 100% !important;
  border-radius: 4px;
  color: white;
  height: 36px !important;
  padding: 5px 9px;
}

.filter-button {
  background-color: #aba8a8;
  height: 40px;
  margin-left: 5px;
  min-width: 200px !important;
}

.documento-chiuso {
  background: greenyellow;
}

.share-formula {
  float: right;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 3px;
  padding-right: 0px;
  color: white;
  background: transparent;
  border: solid 1px $white;
}

.button-text-dropdown {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden;
  display: inline-block !important;
}

.dropdown-warehouse {
  white-space: normal;
  background-color: #dadada;
  border-color: white;
  border-radius: 4px;
  width: 100% !important;
  text-align: left;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: 30px !important;
  padding-top: calc(0.375em - 1px);
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 400;
  
  &.modify {
    color: white;
    background-color: $mylab-color;
  }
}
