@import "./variables.scss";

.bottom-navbar {
  min-height: 56px !important;
  max-height: 56px;
  background-color: #131314 !important;

  box-shadow: 0 -2px 4px 0 rgba(43, 43, 43, 0.5);

  & > * {
    background-color: #131314 !important;
    color: $white;
    width: 100%;
    font-size: 12px;
    text-align: center;

    & img {
      min-width: 24px;
      min-height: 24px;
      margin: 0;
    }
  }

  & .navbar-item {
    min-width: 20%;
    padding: 5px 0 0;
    display: block !important;
    text-align: center;
    & a {
      color: $white !important;
      text-decoration: none !important;
      & p {
        margin-top: -5px;
      }
    }
  }

  & .button.is-fab {
    position: relative;
    top: -16px;
    width: 56px;
    height: 56px;
    background-image: linear-gradient(135deg, #db2754, #981b3b);
    box-shadow: 0 -2px 4px 0 rgba(43, 43, 43, 0.5);
    border-radius: 50%;
    &.is-animated {
      animation: mlnpulse 2s infinite;
    }
  }
}