@import "../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../node_modules/bulma/sass/utilities/functions";

@function rgba-to-rgb($rgba, $background: #fff) {
  @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%);
}

// Update blue
$blue: #72d0eb;
// Add pink and its invert
$mylab-color: #61737b;
$mylab-color-light: #657d88ad;
$mylab-color-invert: findColorInvert($mylab-color);

$mylab-gradient: linear-gradient(96deg, $mylab-color-light, $mylab-color);

$mylab-gray: #1d1d1f;
$mylab-gray-invert: findColorInvert($mylab-gray);

$mylab-light-gray: #444448;
$mylab-lighter-gray: #a7a7a9;

$white-hover: #e7e7e7;

$transparent-hover: rgba(255, 255, 255, 0.2);
$transparent-primary-hover: rgba(219, 39, 84, 0.5);

// Add a serif family
$family-text: "Quicksand", Arial, sans-serif;

// 3. Set the derived variables
// Use the new pink as the primary color
$primary: $mylab-color;
$primary-invert: $mylab-color-invert;
// Use the existing orange as the danger color
$danger: $orange;
// Use the new serif family
$family-primary: $family-text;

// 4. Setup your Custom Colors
$linkedin: #0077b5;
$linkedin-invert: findColorInvert($linkedin);
$twitter: #55acee;
$twitter-invert: findColorInvert($twitter);
$github: #333;
$github-invert: findColorInvert($github);
$facebook: #3b5998;
$facebook-invert: findColorInvert($facebook);
$instagram: #e1306c;
$instagram-invert: findColorInvert($instagram);

$white-ter: rgba(200, 200, 204, 0.1);

$input-disabled-background-color: rgba(246, 248, 249, 0.25);
$input-disabled-border-color: rgba(246, 248, 249, 0.25);
$input-disabled-color: rgba($white, 0.75);

$input-color: $white;
$input-border-color: $white;
//$input-background-color: rgba($white, 0.05);
$input-background-color: $mylab-color;

$consulenza-background: #161617;

$assunzione-background: #ffffff;

$assunzione-formula-background: rgba(200, 200, 204, 0.2);

$assunzione-top-bar-background: #000000;

$mylab-gray-dark: #161617;

$grey-not-so-light: #444448;

$success: #40c44a;
$success-invert: findColorInvert($success);

$navmarginMobile: 67px;
$navmarginDesktop: 74px;

// 5. Add new color variables to the color map.
@import "../../node_modules/bulma/sass/utilities/derived-variables";
$addColors: (
  "twitter": (
    $twitter,
    $twitter-invert
  ),
  "linkedin": (
    $linkedin,
    $linkedin-invert
  ),
  "github": (
    $github,
    $github-invert
  ),
  "facebook": (
    $facebook,
    $facebook-invert
  ),
  "instagram": (
    $instagram,
    $instagram-invert
  ),
  "gray": (
    $mylab-gray,
    $mylab-gray-invert
  ),
  "gray-invert": (
    $mylab-gray-invert,
    $mylab-gray
  ),
  "mylab-light-gray": (
    $mylab-light-gray,
    $white
  ),
  "mylab": (
    $mylab-color,
    $white
  ),
  "consulting-bg": (
    $consulenza-background,
    findColorInvert($consulenza-background)
  ),
  "grey-not-so-light": (
    $grey-not-so-light,
    findColorInvert($grey-not-so-light)
  ),
  "consulenza": (
    $consulenza-background,
    findColorInvert($consulenza-background)
  )
);
$colors: map-merge($colors, $addColors);
$family-header: "Oswald", sans-serif;
$family-primary: "Quicksand", sans-serif;

@keyframes mlnpulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
