@import "./variables.scss";

.admin-panel {
  background: white !important;
  color: black !important;
  min-height: 100vh;

  & .navbar {
    background: #b9bfbc !important;
  }

  & .navbar-item {
    & .button {
      &:hover {
        background: transparent;
      }
    }
    &.active {
    
        border-bottom: 3px solid #5b7682;
 
    }
  }

  & .modal-card {
    max-width: 400px;
    & .muted {
      font-size: 80%;
    }
  }

  & .shipping-info-modal {
    & .modal-card {
      max-width: 300px;
    }
  }

  & .muted {
    opacity: 0.5;
  }

  & .tabs ul li a {
    color: #c9c9c9 !important;
    border-color: #c9c9c9 !important;
  }

  & .tabs ul li.is-active a {
    color: #1d1d1e !important;
    border-color: $mylab-color !important;
    border-bottom: 2px solid;
  }

  & .box {
    border: 1px solid #dedede;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  & .has-text-black {
    color: $mylab-color !important;
  }

  & .has-text-red {
    color: red !important;
  }

  & .admin-header .input {
    margin-left: 30px;
  }

  & .field .control {

    z-index: 9;
    & .input {
      border: 1px solid #dbdbdb;
      background: #f1f1f1;
      color: #1d1d1e;
      font-size: 12px;
      &::placeholder {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.4) !important;
      }
    }

    & .button {
      font-size: 12px;
      height: 27px;
      min-height: inherit;
    }
  }

  & .search-field {
    color: black;
    &::placeholder {
      color: black;
      font-size: 9px;
    }

    &::-webkit-input-placeholder {
      font-family: Quicksand, sans-serif;
      font-size: 9px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #949090 !important;
    }
  }

  & .notif-container {
    position: relative;
  }

  & .notif {
    position: absolute;
    top: 5px;
    right: 0px;
    background: $mylab-color;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 11px;
    line-height: 20px;
    opacity: 0.9;
  }

  & .admin-panels {
    & section {
      margin: 20px 0px 40px 0px;
    }
    & .column {
      padding-bottom: 0px;
    }
    & .button {
      font-size: 12px;
      min-height: inherit;
    }
  }

  & h4,
  & h2 {
    & .subtitle {
      opacity: 0.3;
      font-size: 11px;
      color: black;
      text-transform: none;
      vertical-align: middle;
      position: relative;
      top: -3px;
      left: 7px;
      margin: 0px;
      padding: 0px;
    }
  }

  & .icn {
    background: transparent;
    padding: 0px;
    margin: auto;
    display: block !important;
  }

  & .status-icn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
  }

  .avatar {
    width: auto !important;
    height: auto !important;
  }

  .dashboard-recap {
    margin: 20px 0px;
    & .recap-item {
      text-align: center;
      & h4 {
        font-size: 50px;
        margin: 0px;
        color: #1d1d1e21;
        display: inline-block;
        line-height: 80px;
        width: 80px;
        height: 80px;
        font-weight: bold;
        background: #f1f1f1;
        border-radius: 50%;
        font-family: Helvetica;
        border: 2px solid transparent;
        position: relative;
        & .status-icn {
          // background: #dcd8d9;
          width: 24px;
          height: 24px;
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
      }
      &.active h4 {
        color: #1d1d1e;
        background: #f1f1f1;
        box-shadow: 0px 2px 20px -5px #00000054;
      }
      // &.active .status-icn {
      //   background: $mylab-color;
      // }

      & span {
        opacity: 0.5;
        font-size: 10px;
        display: block;
        // vertical-align: top;
        margin-top: 6px;
      }
    }
  }

  .red-cell {
    background-color: #da3a3a6e;
  }

  .green-cell {
    background-color: #76bf76;
  }

  .myrow:hover {
    background-color: #61737b85 !important;
    color: white !important;
  }

  & .orders-table,
  & .affiliates-table {
    & section {
      table-layout: fixed;
      position: relative;
      margin-bottom: 15px;

      & .table-loader {
        background: #000000d1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 9;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;

        &.show {
          opacity: 1;
          visibility: visible;
        }

        & .image-loader-spin {
          position: absolute;
          top: 40%;
        }
      }
    }

    & table {
      width: 100%;

      & .order-status-dropdown {
        display: block;
        & button {
          display: block;
        }
      }

      & th {
        color:$mylab-color;
        font-size: 14px !important;
        background: #f1f1f1;
        z-index: 9;
      }

      & tr.faded-row {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
      }

      & tr.faded-row.visible {
        opacity: 1;
        visibility: visible;
      }

      & td {
        vertical-align: middle;
        font-size: 13px;
        padding-top: 1px;
        padding-bottom: 1px;

        & .avatar {
          width: 40px;
          height: 40px;
        }

        .cell-icon.blink {
          opacity: 0;
          color: #c9c9c9;
        }

        &:hover .cell-icon.blink {
          opacity: 1;
        }

        & h4 {
          font-weight: bold;
          font-size: 13px;
        }

        & h5 {
          font-size: 10px;
          opacity: 0.6;

          & span {
            font-weight: bold;
          }
        }

        & .cell-icon {
          width: 20px;
          float: right;
          cursor: pointer;
          margin-top: 7px;
          transition: all ease 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }

        & .cart-recap {
          & .image {
            opacity: 0.4;
            margin-bottom: 4px;
          }
          & p {
            font-size: 10px;
          }
          & .p-10 {
            & p {
              font-size: 13px;
            }
          }
        }
      }
    }
    & .pagination {
      margin-bottom: 100px;
      & a {
        background: $mylab-color;
        border-radius: 50%;
        color: white;
        font-size: 16px;
        transition: all ease 0.3s;
        -webkit-transition: all ease 0.3s;
        &:hover {
          color: white;
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
        }
      }
    }
  }

  .dropdown-label {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    opacity: 0.3;
  }

  .order-status-dropdown {
    vertical-align: middle;

    & .status-icn {
      width: 20px;
      height: 20px;
      margin-left: 7px;
      padding: 3px;
    }

    & .dropdown-trigger {
      & .button {
        width: 100%;
        // max-width: 180px;
        padding: 5px;
        font-size: 12px;
        text-align: left;
        &:hover {
          background: inherit;
        }
      }

      & .caret {
        margin-left: 3px;
        opacity: 0.4;
        font-size: 80%;
      }
    }
    & .dropdown-menu {
      min-width: 184px !important;
    }
    & .dropdown-content {
      background: #1d1d1ee8;
      & .dropdown-item {
        font-family: inherit;
        text-transform: inherit;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        color: white;
        cursor: pointer;
      }
      & .dropdown-divider {
        background: #dbdbdb47;
      }
    }
  }
}
