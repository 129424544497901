.user-menu {
  list-style: none;

  & a {
    font-size: 16px;
    text-decoration: none;
    &:last-child li {
      border-bottom: none;
    }
  }
  & li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    & .container {
      padding: 18px;
    }
  }

  & img {
    vertical-align: middle;
    margin-right: 27px;
    width: 24px;
  }
}

.payment-radio {
  padding: 0px 10px;

  & .column:first-child {
    padding-right: 0px;
  }

  & .column:nth-child(2) {
    padding-left: 0px;
  }

  & h4 {
    font-size: 20px;
  }

  & p {
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 0px;
    opacity: 0.5;
    font-size: 16px;
  }

  & img {
    width: 170px;
    margin-bottom: 20px;
  }
}

.payment-list-element {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 0px;

  & .columns {
    padding-right: 20px;
  }

  & h3 {
    font-size: 22px;
  }

  & p {
    opacity: 0.6;
    font-size: 14px;
  }

  &:last-child {
    border-bottom: 0px;
  }
}

h6.user-area-title {
  margin-bottom: 0px !important;
  color: #db2754;
}

h4.user-area-title {
  margin-bottom: 50px !important;
  font-size: 24px;
}

h4.user-area-title-with-subtitle {
  margin-bottom: 20px !important;
  font-size: 24px;
}

.user-form input,
.user-form select {
  background: rgba(35, 35, 42, 0.7) !important;
}

.user-form input:read-only {
  background: inherit !important;
}

.read-only-check .label {
  filter: grayscale(100);
  -webkit-filter: grayscale(100);
}

@media (min-width: 768px) {
  .user-form {
    max-width: 400px !important;
    text-align: center;
    margin: 60px auto 100px auto;
    .button {
      margin: 5px 10px;
    }
  }
}
