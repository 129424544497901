@import "./variables.scss";

// Inputs
.field.input-field {
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(29, 31, 58, 0.7);
  background-color: $input-background-color;
  border: 1px solid $input-border-color;
  margin-bottom: 60px;

  .innerText {
    position: absolute;
    top: 29%;
  }

  &.no-border {
    border: none;
  }

  &.single-help {
    margin-bottom: 30px !important;
  }

  & label,
  & label.label {
    position: absolute;
    top: -5px;
    left: 20px;
    color: white;
    font-family: $family-header;
    text-transform: uppercase;
    background-color: $input-background-color;
    z-index: 10;
    line-height: 0.5;
    border: none;
  }

  &.is-primary label,
  &.is-primary label.label {
    background-color: $mylab-color-light;
  }

  & .select,
  & .select:not(.is-multiple) {
    height: auto;
    width: 100%;
    &:after {
      border-color: $white;
    }
  }

  & .input,
  & .textarea,
  & .select select,
  & .select select:not([multiple]) {
    //background-color: rgba($white, 0.05);
    //border: solid 1px $white;
    min-height: 40px !important;
    border-radius: 7px !important;

    width: 100%;
    background-color: transparent;

 
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $white;

    &.min-height-140 {
      min-height: 140px !important;
      max-height: auto !important;
    }
  }

  &.is-primary {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  &.has-error {
    background-color: transparent;
    border-color: red;
  }

  &.is-disabled {
    background-color: rgba(246, 248, 249, 0.25);
  }

  & .help {
    position: absolute;
    top: calc(100%);
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: rgba($white, 0.75);
  }

  & .control {
    & .input {
      width: auto !important;
    }

    & .column {
      & .button {
        z-index: 9;
        margin-top: 18%;
      }
    }

    &.has-icons-right .input,
    &.has-icons-right .select select {
      padding-right: 0 !important;
      //margin-right: 2.25rem;
    }

    &.has-icons-left .input,
    &.has-icons-left .select select {
      padding-left: 0 !important;
      //margin-left: 2.25rem;
    }
  }

  &.search {
    box-shadow: 0 1px 2px 0 rgba(29, 31, 58, 0.7);
    margin-bottom: 20px !important;
    background-color:$mylab-color;

    .input {

      width: 100% !important;
      background-color: $mylab-color;
    }
  }
}

.formula-description {
  position: relative;
  border-radius: 0;
  box-shadow: 0 1px 2px 0 rgba(29, 31, 58, 0.7);
  background-color: $input-background-color;
  border: 1px solid $input-border-color;
  .innerText {
    position: absolute;
    top: 29%;
  }
  & label,
  & label.label {
    position: absolute;
    top: -5px;
    left: 20px;
    color: white;
    font-family: $family-header;
    text-transform: uppercase;
    background-color: $input-background-color;
    z-index: 10;
    line-height: 0.5;
    border: none;
  }
  & .input,
  & .textarea,
  & .select select,
  & .select select:not([multiple]) {
    width: 100%;
    background-color: transparent;
    border: none;
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $white;
  }
}

.label-consulenza {
  font-family: $family-header;
  text-transform: uppercase;
  color: white;
}

.DateInput_input::placeholder {
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgb(128, 128, 139) !important;
}

// ::placeholder {
//   font-family: Quicksand, sans-serif;
//   font-size: 18px;
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: rgba($white, 0.65) !important;
// }

// :-moz-placeholder {
//   font-family: Quicksand, sans-serif;
//   font-size: 18px;
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: rgba($white, 0.65) !important;
// }

// ::-moz-placeholder {
//   font-family: Quicksand, sans-serif;
//   font-size: 18px;
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: rgba($white, 0.65) !important;
// }

// :-ms-input-placeholder {
//   font-family: Quicksand, sans-serif;
//   font-size: 18px;
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: rgba($white, 0.65) !important;
// }

// ::-ms-input-placeholder {
//   font-family: Quicksand, sans-serif;
//   font-size: 18px;
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: rgba($white, 0.65) !important;
// }

// ::-webkit-input-placeholder {
//   font-family: Quicksand, sans-serif;
//   font-size: 18px;
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: rgba($white, 0.65) !important;
// }

// checkbox and radios

.is-checkradio[type="checkbox"] + label {
  padding: 0 !important;
  padding-left: 2rem !important;
}

.is-checkradio[type="checkbox"].is-primary:hover:not([disabled])
  + label::before,
.is-checkradio[type="checkbox"].is-primary:hover:not([disabled])
  + label:before {
  border-color: $mylab-color-light !important;
}

.is-checkradio[type="checkbox"]:checked + label::before,
.is-checkradio[type="checkbox"]:checked + label:before {
  border: 0.1rem solid $mylab-color-light !important;
  background-color: $mylab-color-light !important;
  //background: url("/images/check.svg") no-repeat;
  //background-size: contain;
}

.is-checkradio[type="checkbox"].is-primary:checked + label::after,
.is-checkradio[type="checkbox"].is-primary:checked + label:after {
  border-color: $mylab-gray-dark !important;
}

.is-checkradio[type="radio"]:checked + label::before,
.is-checkradio[type="radio"]:checked + label:before {
  border: 0.1rem solid $mylab-color-light !important;
  //background-color: $mylab-color-light !important;
  //background: url("/images/check.svg") no-repeat;
  //background-size: contain;
}

.field .has-checkradio {
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}

.no-click{
  pointer-events: none;
}

.blocked {
  background-color: #dadada;
  color: black;
  pointer-events: none;
}

.link-product {
  &.greyed {
    background-color: #dadada;
  }
  color: black;
  border: 1px solid transparent;
  border-radius: 14px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 2.25em;
  width: 100%;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
  align-items: center;
  overflow: hidden;
  display: inline-block;
}

.inverted {
  &.field.input-field {
    background-color: $white !important;
    color: $mylab-color !important;
    border-color: #c8c8c8 !important;
  }

  & label,
  & label.label {
    background-color: $white !important;
    color: $black !important;
  }

  &.is-primary label,
  &.is-primary label.label {
    background-color: $white !important;
    color: $mylab-color !important;
  }

  & .input,
  & .textarea,
  & .select select,
  & .select select:not([multiple]) {
    color: $mylab-color !important;
    border: none !important;

    &[disabled] {
      color: #c8c8c8 !important;
    }
  }

  & .select,
  & .select:not(.is-multiple) {
    &:after {
      border-color: $black !important;
    }
    &[disabled] {
      &:after {
        border-color: #c8c8c8 !important;
      }
    }
  }

  ::placeholder {
    color: $mylab-color-light !important;
  }

  :-moz-placeholder {
    color: $mylab-color-light !important;
  }

  ::-moz-placeholder {
    color: $mylab-color-light !important;
  }

  :-ms-input-placeholder {
    color: $mylab-color-light !important;
  }

  ::-ms-input-placeholder {
    color: $mylab-color-light !important;
  }

  ::-webkit-input-placeholder {
    color: $mylab-color-light !important;
  }
}
