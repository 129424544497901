@import "./variables.scss";

.modal {
  z-index: 1001 !important;
}

.modal-card {
  background-color: $mylab-gray-dark;
  border-radius: 0;

  & .modal-card-head {
    color: $white;
    & .modal-card-title {
      font-family: Oswald, sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      text-align: left;
      padding-left: 30px;
      font-size: 20px;
    }
    & .delete {
      font-size: 20px;
      color: $white;
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      //noinspection CssUnknownTarget
      background-size: contain;

      &:before,
      &:after {
        content: " ";
      }
    }
  }

  & .modal-card-head,
  & .modal-card-title,
  & .modal-card-body,
  & .modal-card-foot {
    border: none;
    background-color: $mylab-gray-dark;
    color: $white;
  }
}

.modal-card.is-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  max-height: 100vh;
}

.warehouse-modal {
  & .modal-background,
  & .modal-card & .modal-card-head,
  & .modal-card .modal-card-title,
  & .modal-card & .modal-card-body,
  & .modal-card-body,
  & .modal-card & .modal-card-foot {
    background-color: transparent !important;
  }

  & .modal-card {
    max-width: 900px !important;
    width: 900px;
    background-color: #f7f7f7;
    border: 1px solid #bfbfbf;
    border-radius: 20px;
    height: 400px;
  }

  & .button {
    width: 100px !important;
    margin-top: -50px;
    height: 40px;
  }
}
