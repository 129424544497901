//@import "loader.scss";
// 1. Import the initial variables
@import "../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../node_modules/bulma/sass/utilities/functions";
@import url("https://fonts.googleapis.com/css?family=Oswald:500,700|Quicksand:500,700");
@import url("https://assets.mylabnutrition.net/fonts/fonts.css");
// 2. Set your own initial variables
@import "./variables.scss";
// 6. Import the rest of Bulma
@import "../../node_modules/bulma/bulma";
//@import "../../node_modules/ag-grid/dist/styles/ag-grid.css";
//@import "../../node_modules/ag-grid/dist/styles/ag-theme-balham.css";
//@import "./footer.scss";
//@import "./gif.scss";
//@import "./LineShape.scss";
@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick-theme.css";
@import "../../node_modules/bulma-extensions/bulma-checkradio/src/sass/index";
@import "../../node_modules/bulma-extensions/bulma-badge/src/sass/index";
@import "../../node_modules/rc-slider/dist/rc-slider.css";
@import "../../node_modules/css-star-rating/css/star-rating.css";

// 7. Custom style to override Bulma if needed

.body {
  height: 100vh;
}
.input, .textarea, .select select
{
  border-radius: 7px !important;
}

.hidden-start {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.zoom-hoover {
  transition: transform 0.2s; /* Animation */
}

.zoom-hoover:hover {
  transform: scale(1.05);
}

.sticky-header-table {
  overflow-y: auto;
  max-height: 800px;
  & th {
    position: sticky;
    top: -1px;
  }
}

.has-text-subtitle {
  font-family: $family-primary !important;
  text-transform: none !important;
}

.has-text-green {
  color:#61737b !important;
}

.m-consulenza-container {
  min-height: 100vh;
  background-color: $consulenza-background;
  padding-bottom: 60px;
  position: relative;
}

.consulenza {
  min-height: 100vh;
  max-width: 500px;
  margin: auto;
}

.consulenza-close {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  margin-top: 1.5px;
}

.mln-media-product-list {
  box-shadow: inset 0 -1px 0 0 rgba(161, 161, 161, 0.5);
  .media-content {
    overflow: hidden;
  }
}

.pulse-animation {
  animation: mlnpulse 2s infinite;
}

.has-text-centered {
  & .image {
    margin: auto;
  }
}

.nostra-fornitura-row {
  background: yellow !important;
}

.notification {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  min-height: 18px;
  height: auto;
  padding: 5px 8px;
  text-align: center;
}

.alert-legend {
  width: 250px;
  font-size: 12px;
 }

.square-alert {
  border: 1px solid black;
  width: 15px !important;
  height: 15px !important;
  float: left;
  margin-top: 2px;
  margin-right: 5px;
}

.alert_red {
  background-color: #ff454573;
}

.alert_orange {
  background-color: #e070258f;
}

.alert_yellow {
  background-color: #fbfb88;
}

.alert_green {
  background-color: #92c392;
}

.dropdown-item {
  & a {
    color: black;
    text-decoration: none;
  }
}

.tre-quadri-bg {
  display: block;
  width: 100%;
  height: 180px;
  padding-top: 50px;
  text-align: center;
  //noinspection CssUnknownTarget
  background-size: contain;

  & img {
    height: 110px;
    width: 110px;
    margin: auto;
    border: solid 2px #ffffff;
    border-radius: 50%;
  }
}

.mylab-percentage {
  height: 60px;
  width: 100vw;
  margin: 0 0 0 -0.75rem;
  background-image: linear-gradient(99deg, #db2754, #981b3b);
  padding: 30px 15px 20px;
}

.muted {
  opacity: 0.5;
}

// TABS

.tabs {
  font-family: $family-header;

  a {
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom-color: $mylab-light-gray;
  }

  ul {
    border-bottom-color: $mylab-light-gray;

    li {
      width: 100%;

      &.is-active {
        a {
          color: $white;
          border-bottom-color: $mylab-color-light;
        }
      }
    }
  }
}

// input icon
.control {
  &.has-icons-left {
    .icon {
      top: 30%;
      width: 3.25em;
      margin-top: -0.5rem;
    }
  }
}

// custom progressbar
.progressbar {
  width: 100%;
  &.is-consulenza {
    border-radius: 0;
    min-height: 2px;
    max-height: 2px;
    background-color: rgba($white, 0.1);
    position: relative;
    display: block;
    & .value {
      min-height: 2px;
      display: block;
      position: relative;
      left: 0;
      top: 0;
      background-color: $white;

      &:before {
        position: relative;
        content: "";
        display: block;
        min-height: 2px;
        max-width: 2px;
        margin-left: calc(100% - 2px);
        background-color: white;
        box-shadow: 0.5px -0.5px 0 0.3px white, 0.5px -3.5px 0 0.3px white,
          0.5px -5px 0 0.3px white;
      }

      &:after {
        position: relative;
        top: -30px;
        display: block;
        max-height: 0;
        content: attr(data-value) "%";
        background-color: transparent;
        padding-left: calc(100% - 10px);
      }
    }
  }
}

.landing-lab {
  display: block;
  width: 100%;
  height: 180px;
  text-align: center;

  & img {
    max-height: 180px;
    margin-left: -30px;
  }
}

.map {
  width: 100%;
  height: 200px;
  &:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(121deg, rgba(219, 39, 84, 0.3), #981b3b);
  }
}

.formula-list-element {
  background-color: transparent;
  box-shadow: inset 0 -1px 0 0 #ffffff;
  padding: 35px 60px 16px 16px;
  overflow: hidden;
  position: relative;
}

//noinspection CssUnknownTarget
.lab-box {
  background: #131314;
  box-shadow: 0 2px 14px 0 rgba(97, 97, 97, 0.35);
  padding: 35px 60px 16px 16px;
  overflow: hidden;
  position: relative;
  min-height: none;
  max-height: none;

  &.min-height-140 {
    min-height: 140px !important;
    max-height: auto !important;
  }

  .name-col {
    padding-top: 20px;
    padding-left: 0px;
  }

  .actions-col {
    padding: 0px 0px 15px 20px;
    & svg {
      margin-top: 10px;
    }
  }

  & .subtitle {
    margin-bottom: 0;
  }

  &.group-17.user-header {
    background-position: 100% -30px;
  }
  &.group-17.business-header {
    background-position: 122% -30px;
    min-height: 250px;
    vertical-align: middle;
  }
  &.group-19 {
    background-position-x: 130%;
    background-size: contain;
  }

  background-position-x: 130%;
  background-size: contain;

  & > div.img {
    display: contents;
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;

    & img {
      position: absolute;
      right: -5%;
      top: 25%;
    }
  }
}

.lab-area {
  .modal-card {
    overflow-y: auto;
  }
}

// business area

.revenue-stats-graph {
  &.columns {
    @media screen and (min-width: 1024px) {
      background: $white;
      color: #838385;
      font-size: 13px;
    }
  }

  .field {
    &.input-field {
      @media screen and (min-width: 1024px) {
        background: $white;
        border: 0;
        box-shadow: none;
      }
      .input {
        @media screen and (min-width: 1024px) {
          color: $black;
          border: 0;
          font-size: 13px;
          min-height: 30px !important;
          box-shadow: inset 0px 0px 6px 0px #ccc;
        }
      }
    }
  }
}

.business-area {
  .lab-box {
    &.group-17 {
      @media screen and (min-width: 1024px) {
        background-position: 100% 100%;
        background-size: auto;
        color: #000;
      }
      .mt-80 {
        @media screen and (min-width: 1024px) {
          margin: 0 !important;
        }
      }
    }
  }
}

// minor fixes

.container {
  padding: 0.75em;
}

.nav-container {
  width: 100%;
  margin: auto;
}

.has-background-dark {
  background-color: #131314 !important;
}

.navbar-link,
.navbar-item {
  color: $white;
}

.navbar-item:hover,
.navbar-link:hover {
  background: none;
  color: $white;
}

.navbar-link .badge {
  background: #ce204c;
  color: #ffffff;
}

.mobile-navbar.full-transparent {
  background: rgba(29, 29, 31, 0.5) !important;
}

.mobile-navbar.full-transparent > * {
  background: transparent !important;
}

// dropdown for menu
@import "./dropdown.scss";

// Modals
@import "./modals.scss";

// Printed doc
@import "./printedDoc.scss";

// standard fonts
@import "./typings.scss";

// mobile navbar
@import "./mobile-navbar.scss";

@import "./bottom-navbar.scss";

.filewrapper {
  input {
    display: none;
  }
}

.img-flip-h img {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.revenueValue {
  height: 63px;
  font-family: Quicksand;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
// mobile navbar - fix for bulma

.navbar {
  &,
  & > div,
  & .navbar-menu,
  & .navbar-start,
  & .navbar-end {
    align-items: stretch;
    display: flex;
    padding: 0;
  }

  & .navbar-item {
    display: flex;
  }

  & .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  & .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
    margin-left: auto;
  }

  & .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }
}

// body
html,
body {
  background: $mylab-gray;
  color: $white;
  font-family: Quicksand, sans-serif;
  font-weight: 500;
}

// UI
@import "./UI.scss";
// user
@import "./user.scss";

// inputs
@import "./inputs.scss";
@import "./slider.scss";

//ADMIN
@import "./adminPanel.scss";

// MTestimonials

@import "./mtestimonials.scss";

// MLNRectangle

.mln-rectangle {
  display: block;
  position: relative;
  transform: rotate(50deg);
  background-image: linear-gradient(135deg, #db2754, #981b3b);
}

.red-rectangle {
  background-image: linear-gradient(126deg, #db2754, #981b3b);
}

// UTILS
@import "./utils.scss";

@import "./loader.scss";

@import "./Home.scss";

@import "./mlnbox.scss";

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.is-formula-detail {
  //noinspection CssUnknownTarget
  background-size: 50vw;
}

.is-primary-to-light {
  background: linear-gradient(167deg, #db2754, #981b3b);
}

.unwrap {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.packaging-formula-order {
  position: relative;
  display: contents;
  &img {
    min-width: 156px;
    position: relative;
    left: -35px;
    top: -15px;
  }
}

.m-formula-order-box {
  max-height: 260px;
  margin-bottom: 50px;
}

.element-box {
  //width: 156px;
  min-height: 200px;
  background-color: #131314;
  box-shadow: 0 2px 14px 0 rgba(97, 97, 97, 0.35);
  padding: 20px;
  position: relative;
  & img {
    height: 67px;
  }

  .added-item {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    top: -5px;
    right: -5px;
    text-align: center;
    background-color: #40c44a;
    font-size: 12px;
  }
}

.legend-bullet {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: solid 2px #ffffff;
  display: inline-block;
  border-radius: 50%;
}

.rating {
  &.large {
    & .star {
      width: 22px !important;
      height: 22px !important;

      & i {
        font-size: 20px !important;
        line-height: 20px !important;
      }
    }
    & .label-value {
      font-size: 22px !important;
      line-height: 22px !important;
    }
  }
  &.medium {
    & .star {
      width: 14px !important;
      height: 14px !important;

      & i {
        font-size: 12px !important;
        line-height: 12px !important;
      }
    }
    & .label-value {
      font-size: 14px !important;
      line-height: 14px !important;
    }
  }

  &.small {
    & .star {
      width: 12px !important;
      height: 12px !important;

      & i {
        font-size: 10px !important;
        line-height: 10px !important;
      }
    }
    & .label-value {
      font-size: 12px !important;
      line-height: 12px !important;
    }
  }
}

.image.is-rounded {
  & img {
    border-radius: 50% !important;
  }
}

.image {
  &.is-90x90 {
    height: 90px;
    width: 90px;
  }
}

.image.is-profile-photo img,
img.is-profile-photo {
  border: 2px solid #ffffff85;
}

.border-bottom {
  box-shadow: none;
  border-bottom: 1px solid $white;

  &.grey {
    border-color: #b3b3b3;
  }
}

.has-right-border {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 30%;
    left: 100%;
    width: 0px;
    height: 30px;
    border: 1px solid transparent;
    border-right-color: white;
  }
}

.arrow--right {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 92%;
    width: 10px;
    height: 10px;
    border-color: transparent white white transparent;
    border-style: solid;
    border-width: 1px;
    transform: rotate(-45deg);
  }

  &.up {
    &:after {
      transform: rotate(225deg);
    }
  }

  &.down {
    &:after {
      transform: rotate(45deg);
    }
  }
}

.tag-scroll {
  max-width: 700px;
}

.tag {
  &.my-lab {
    border-radius: 0;
    text-transform: none !important;
    border: 1px solid $mylab-color-light;
    color: $white;
    background: transparent;
    margin: 3px;
    &.selected {
      background-color: $mylab-color-light;
    }
  }
  &.dose {
    border-radius: 0;
    color: $white;
    background: #da0000;
    margin: 3px;
    white-space: inherit;
    height: auto;
    padding: 5px;
  }
}

.has-background-gray {
  background: #737373 !important;
}

.m-container,
.m-container .mylab-percentage {
  max-width: 375px;
  width: 375px;
}

#root {
  max-width: 100vw;
  overflow-x: hidden;
}

.is-fullwidth {
  width: 100%;
}

.my-formula-bottom {
  width: 100%;
  position: fixed;
  margin-bottom: 0px;
  bottom: 0;
  right: 0;
  z-index: 1000;

  .my-formula-bottom-head {
    position: relative;
    background: #db2754;

    padding: 10px;

    h6 {
      font-size: 14px;
    }
  }

  .my-formula-bottom-body {
    display: none;
    background-image: linear-gradient(to bottom, #db2754 0%, #444448 30%);
  }

  .element-img {
    width: 64px;
  }

  .arrow--right {
    &:after {
      transform: rotate(225deg);
    }
  }

  .gradient-bar {
    padding-top: 80px;
    padding-bottom: 80px;
    background: white;
  }

  &.opened {
    top: auto;
    bottom: 0px;
    max-height: 700px;
    max-height: 84vh;
    overflow-y: auto;

    .my-formula-bottom-head {
      h6 {
        font-size: 20px;
      }

      h2 {
        display: none;
      }
    }

    .my-formula-bottom-body {
      display: block;
    }

    .arrow--right {
      &:after {
        top: 25%;
        transform: rotate(45deg);
      }
    }
  }
}

.tag-delete-icon {
  margin-left: 5px;
  margin-top: -7px;
}

.card.recipe-box {
  background-color: #131314;
  box-shadow: 0 2px 14px 0 rgba(97, 97, 97, 0.35);
  color: $white;
  margin: 8px 0;

  &.order {
    background: rgba(97, 97, 97, 0.45);
    & .notification {
      max-width: 400px;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-top: 0;
  }
  & a {
    text-decoration: inherit;
  }
  & .card-header {
    & .card-header-title {
      display: block !important;
      & * {
        display: block;
        margin: 0;
      }
    }

    & * {
      color: $white;
    }
  }
  & .card-content {
    padding-top: 0;
    & .columns.recipe-dots {
      padding: 0;
      & ul {
        color: $grey-light;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

.cc-icon {
  display: none;
}
.cc-dialog-container {
  bottom: 40px;
}

ul.dots {
  list-style: disc;
}

ul.dots-margin {
  list-style: disc;
  margin-left: 20px;
}

.table-header-left {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  width: 50%;
}
.table-header-center {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  width: 25%;
}

.table-header-right {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  width: 25%;
}

.table-value-left {
  border-right: 1px solid white;
  width: 50%;
  padding-bottom: 5px;
  padding-top: 5px;
}
.table-value-center {
  width: 25%;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
}

.table-value-right {
  border-left: 1px solid white;
  width: 25%;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
}

.payment-summary {
  & .summary-header {
    & h5 {
      padding-top: 3px;
      font-size: 20px;
    }
  }
  & p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.order-section {
  text-align: center;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 20px 0px 0px 20px;
  margin: 20px 0px;

  & .title {
    margin-bottom: 5px;
    font-size: 1rem;
  }

  & p {
    margin-bottom: 20px;
    opacity: 0.7;
  }
}

.top-bar {
  height: 80px;
  background-color: $assunzione-top-bar-background;
  width: 100%;
  padding: 20px;
  padding-left: 100px;
}

.tb-logo {
  float: left;
}
.tb-logo-print {
  float: left;
  display: none;
}
.tb-title {
  float: left;
  margin-left: 10%;
  margin-top: 10px;
}
.assunzione-bg-img {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 28%;
  margin-top: -67px;
  margin-right: -25px;
}
.footer-drops {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  width: 28%;
  margin-bottom: -67px;
  margin-right: -25px;
}

.scrollcontainer {
  padding: 0px;
  border: solid 1px #e0d2d2;
  white-space: nowrap;
  overflow-x: auto;
}

.separator-line {
  width: 100%;
  height: 1px;
  border: solid 1px #c8c8cc;
  margin-top: 50px;
  margin-bottom: 50px;
}

.separator-line-5 {
  width: 100%;
  height: 1px;
  border: solid 1px #c8c8cc;
  margin-top: 5px;
  margin-bottom: 5px;
}

.riepilogo-prezzi {
  & table {
    & th {
      font-size: 12px !important;
    }

    & td {
      font-size: 12px !important;
    }
  }
}
.formula-table-bg {
  background-color: $assunzione-formula-background;
}

.documento-assunzione-container {
  position: relative;
}

.assunzione-element-img {
  height: 60px;
  float: left;
  margin-left: 8px;
  margin-top: 20px;
}

.centered_container {
  position: relative;
  display: block;
}

.centered_element {
  float: left;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.centered {
  margin: auto;
  display: block !important;
}
.funcional-categories-container {
  width: 100%;
  padding: 10px;
}
.w-30 {
  float: left;
  width: 42%;
  padding: 10px;
}
.w-45 {
  float: left;
  width: 45%;
}
.fl {
  float: left;
}
.composition-container {
  min-height: 200px;
}
.funcional-categories {
  float: left;
  width: 40%;
  padding-left: 10px;
  padding-bottom: 15px;
  padding-right: 15px;
}

.funcional-categories-ol {
  margin-left: 14px;
}

.funcional-categories-graph {
  float: left;
  width: 60%;
}
.formula-name {
  font-family: Quicksand;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #444448;
}
.formula-creator {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #a0a0a1;
}
.formula-desc {
  font-family: Quicksand;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #444448;
}
.navbar-element {
  & a {
    display: block;
    text-decoration: none;
    margin: 8px auto !important;
    font-size: 16px;
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
  }
}

.has-text-white {
  color: #ffffff !important;
}

.has-text-red {
  color: red !important;
}

.quality-box {
  max-width: 700px;
  margin: auto;
}

.privacy-policy {
  & ol,
  ul {
    padding-left: 20px;
    & li {
      padding: 10px 0px;
    }
  }
  & p {
    margin: 10px 0px;
  }
}

.user-formulas {
  .tabs li {
    max-width: 200px;
  }
  .search {
    max-width: 400px;
  }
}

.vw-desktop {
  display: none !important;
}
.vw-tablet {
  display: none !important;
}

/*smaller screens - <= iPhone 5 */
@media (max-width: 340px) {
  .elements-menu-actions .button:last-child {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*tablets & up*/
@media (min-width: 768px) {
  .vw-hide-tablet {
    display: none !important;
  }

  .vw-tablet {
    display: block !important;
  }
  span.vw-tablet {
    display: inline-block !important;
  }
  .vw-mobile-only {
    display: none !important;
  }
  .navbar-item {
    float: left;
    padding: 0.5rem 1.1rem;
  }

  .cc-icon {
    display: block;
  }

  .landing-lab {
    & img {
      margin-left: 0px;
    }
  }

  .dropdown-user {
    .dropdown-content {
      min-width: 164px !important;
    }
  }

  .container,
  .nav-container {
    width: 90% !important;
  }

  .button {
    display: inline-block !important;
    width: auto !important;
    min-width: 0px !important;
    max-width: none !important;
  }

  .centered {
    margin: auto;
    display: block !important;
  }
  .element-box {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.43);
  }

  .map {
    height: 400px;
  }

  .card.recipe-box {
    margin: 20px 0;
    -webkit-box-shadow: 0px 5px 9px -7px black;
    box-shadow: 0px 5px 9px -7px black;
  }

  .is-formula-detail {
    //noinspection CssUnknownTarget
    background: url("");
    position: relative;
    //background-size: 30vw;
    .packaging-formula-order {
      display: block;
      text-align: center;
    }
    .m-formula-order-box {
      max-height: none;
      & h6.title {
        font-size: 20px;
        span {
          font-size: 20px;
        }
      }
      & img {
        margin: -90px auto 0px auto;
        width: 60%;
      }
      & .button {
        width: 100% !important;
        max-width: none !important;
      }
    }
    .recipe-details {
      border-top: 1px solid #80808038;
      border-bottom: 1px solid #80808038;
      margin: 0px 1px;
      & .column:nth-child(2) {
        border-left: 1px solid #80808026;
        border-right: 1px solid #80808026;
      }
    }
    .recipe-actions {
      .button {
        width: 100% !important;
      }
    }
    .recipe-info {
      border-top: 1px solid gray;
      margin-top: 50px;
    }
    .element-box {
      img {
        width: 90px;
        height: 90px;
      }
      .content {
        padding-top: 20px;
      }
      p {
        margin: 5px 0px 0px 0px;
      }
      .button {
        margin-top: 10px;
      }
    }
  }

  .cart-steps {
    margin-top: -24px;
  }

  .laboratorio-container {
    h3.title {
      text-align: center;
      font-size: 30px;
    }
    h3.subtitle {
      text-align: center;
      font-size: 20px;
      opacity: 0.7;
    }
    .lab-choices {
      max-width: 600px;
      margin: auto;
      margin-top: 80px;
      .lab-box-d {
        background: #1d1d1e;
        box-shadow: 0px 2px 25px -10px black;
        -webkit-box-shadow: 0px 2px 25px -10px black;
        -moz-box-shadow: 0px 2px 25px -10px black;
        -ms-box-shadow: 0px 2px 25px -10px black;
        -o-box-shadow: 0px 2px 25px -10px black;
        position: relative;
        padding: 0px 60px 30px 30px;
        overflow: hidden;
        h4.title {
          margin-top: 170px;
        }
        .squares-bg-element {
          height: auto;
          min-height: none;
          margin: 0px;
          width: 170px;
          & img {
            width: 100px;
            position: relative;
            left: 30px;
          }
        }
        .img {
          position: absolute;
          right: -20px;
        }
      }
    }
  }

  .consulenza {
    padding: 60px 0px 200px 0px;

    margin: auto;
    min-height: 0px;
  }
  .m-consulenza-container {
    .mylab-percentage {
      width: 100%;
      margin: 0px;
    }
    h3.title {
      font-size: 30px;
      margin-bottom: 20px;
    }
    .consulenza-form {
      max-width: 500px;
      margin: auto;
    }
  }

  .mln-media-product-list {
    .media-content {
      padding-top: 7px;
    }
    .flavour-actions {
      padding-top: 10px;
    }
  }

  .lab-area {
    .lab-title {
      text-align: center;
      display: block;
      font-size: 1.8rem;
      margin-top: 40px;
    }
    h6.title {
      text-align: center;
      margin: 30px 0px 20px 0px;
    }
    .lab-section {
      p {
        text-align: center;
        font-size: 1.3rem;
      }
    }
    .element-img {
      width: 100px;
      height: 100px;
    }

    .add-element-form,
    .my-formula-bottom-details {
      max-width: 400px;
      margin: auto;
    }

    .lab-box {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
      padding-top: 10px;
      padding-bottom: 0px;
      .name-col {
        padding-top: 30px;
      }
      .actions-col {
        padding: 0px 0px 0px 25px;
        margin: -20px 0px 25px 0px;
        & svg {
          margin-top: 10px;
        }
      }
    }

    .input-field.search {
      max-width: 400px;
      margin: auto;
      margin-bottom: 30px !important;
    }
    .my-formula-bottom {
      position: static;
      overflow: auto;
      max-height: none;
      margin: 0px auto 100px auto;
      .my-formula-bottom-head,
      .my-formula-bottom-body,
      .my-formula-bottom-body.opened {
        background: #444448;
        display: block;
      }
      .my-formula-bottom-body {
        padding-bottom: 60px;
        .recap-btn {
          display: none !important;
        }
      }
      .arrow--right:after {
        display: none;
      }
      .my-formula-bottom-head {
        h6 {
          font-size: 1.6rem !important;
        }
      }
    }
  }

  .order-details {
    padding: 50px 30px 0px 30px;
  }

  .order-section {
    border-top: 1px solid #ffffff24;
    border-bottom: 1px solid #ffffff24;
  }

  .modal-card-foot .button {
    margin: 0px auto 20px auto;
  }
}

/*desktop & up*/
@media (min-width: 1025px) {
  .vw-desktop {
    display: block !important;
  }
  span.vw-desktop {
    display: inline-block !important;
  }

  .container,
  .nav-container {
    width: 85% !important;
    max-width: 1280px !important;
  }
  .container-table,
  .nav-container {
    width: 90% !important;
    max-width: 90% !important;
  }

  .is-formula-detail {
    .element-box {
      img {
        width: 160px;
        height: 160px;
      }
    }
  }

  .checkout-container {
    max-width: 500px !important;
    h4.title {
      font-size: 30px;
      text-align: center;
      margin: 15px 0px !important;
    }
    .help {
      opacity: 0.5;
    }
    .button {
      width: 100% !important;
    }
  }

  .lab-area {
    .lab-box {
      .actions-col {
        margin: 0px;
        padding: 40px 0px 0px 0px;
      }
    }
  }
}

.assunzione {
  background-color: $assunzione-background;
}

.assunzione-column-40 {
  width: 40%;
  float: left;
}
.assunzione-column-20 {
  width: 20%;
  float: left;
}
.assunzione-drops {
  //float: left;
  text-align: center;
}
.assunzione-footer-text {
  color: white;
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  color: $white;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
}

.assunzione-footer {
  background: black;
  padding: 35px;
}

.clear {
  clear: both;
}
.assunzione-drops-value {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #444448;
}
.assunzione-drops-description {
  font-family: Quicksand;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: center;
  color: #737373;
}
.assunzione-section-container {
  page-break-inside: avoid;
}
.assunzione-what {
  width: 45%;
}
.assunzione-how-when {
  width: 30%;
}

// @media print {
//   .page-break {
//     display: block;
//     page-break-before: always;
//   }
//   size: A4 portrait;
// }

// @media print {
//   * {
//     -webkit-transition: none !important;
//     transition: none !important;
//   }
//   .tb-logo {
//     display: none;
//   }
//   .tb-title {
//     color: black;
//   }
//   .tb-logo-print {
//     display: block;
//   }
//   .formula-table-bg {
//     background-color: white;
//   }
//   .funcional-categories {
//     width: 300px !important;
//     padding-left: 10px;
//     margin: 10px;
//     display: block;
//   }
//   .funcional-categories-graph {
//     display: block;
//     width: 300px !important;
//     margin-top: 10px;
//     padding-left: 10px;
//   }
//   .funcional-categories-container {
//     width: 100%;
//   }

//   .assunzione-footer-text {
//     color: black;
//   }

//   .assunzione-footer {
//     background: white;
//   }

//   .assunzione-what {
//     width: 50%;
//   }
//   .assunzione-how-when {
//     width: 25%;
//   }
// }

.filter-text {
  font-size: 12px;
  margin-left: 6px;
}

.reports-search {
  height: 17px;
  border: none;
  margin-top: 0px;
  width: 300px;
  margin-bottom: 0px;
}

.DateInput_input {
  font-size: 17px;
}

//Google geosuggest
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__input {
  width: 100%;
  background: transparent;
  border: none;
  height: 38px;
  border-radius: 30px;
  font: 400 11px system-ui;
  color: white;
  font-family: Quicksand, sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.geosuggest__input-wrapper {
  width: 400px;
}

.blocked {
  .geosuggest__input {
    width: 100%;
    background: transparent;
    border: none;
    height: 38px;
    border-radius: 30px;
    font: 400 11px system-ui;
    color: black;
    font-family: Quicksand, sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
}

.geosuggest__item {
  background: white;
  color: black;
  margin-bottom: 10px;
}

.dot-green {
  height: 15px;
  width: 15px;
  background-color: #87bd33;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
  margin-right: -40px;
  vertical-align: middle;
}

td.red {
  .chakra-switch__track {
    background-color: red;
  }
}
td.green {
  .chakra-switch__track {
    background-color: #61737b;
  }
}


.DateRangePicker_picker {
  z-index: 99;
}
// buttons
@import "./buttons.scss";
