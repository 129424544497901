@import "./variables.scss";

.logofont {
  font-family: "NeoLatina", sans-serif;
  text-transform: none;
}

.content .title,
.content .subtitle {
  color: $white;
}

.title {
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  color: $white;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
}

.subtitle {
  font-family: Quicksand, sans-serif;
  color: $white;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  &.small {
    font-size: 14px;
    color: #a09f9f;
  }
}

h1 {
  &.title {
    font-size: 36px;
  }
  &.subtitle {
    font-size: 20px;
  }
}

h2 {
  &.title {
    font-size: 28px;
  }
  &.subtitle {
    font-size: 18px;
  }
}

h3 {
  &.title {
    font-size: 20px;
  }
  &.subtitle {
    font-size: 16px;
  }
}

h4 {
  &.title {
    font-size: 18px;
  }
  &.subtitle {
    font-size: 14px;
  }
}

h5 {
  &.title {
    font-size: 16px;
  }
  &.subtitle {
    font-size: 12px;
  }
  &.read-more {
    color: #db2754 !important;
    text-transform: uppercase;
    font-weight: bold;
  }
}

h6 {
  &.title {
    font-size: 14px;
  }
  &.subtitle {
    font-size: 10px;
  }
}

.text-red {
  color: #db2754;
}

.text-black {
  color: #000000;
}
.text-red {
  color: #e9416b;
}

.text-gray {
  color: #838385;
}

.has-text-18 {
  font-size: 18px;
  font-weight: 500;
}

.has-text-mylab-color-light {
  color: $mylab-color-light;
}

.has-super-light-gray {
  color: #b3b3b3;
}

.has-text-23 {
  font-size: 23px !important;
  line-height: 14px;
}
.has-text-11 {
  font-size: 11px;
}
.has-text-13 {
  font-size: 13px;
}
.has-text-20 {
  font-size: 20px;
}
.has-text-24 {
  font-size: 24px;
}
.has-text-14 {
  font-size: 14px !important;
  line-height: 14px;
}

.has-normal-weight {
  font-weight: normal;
}

.has-white {
  color: $white;
}

.is-bolder {
  font-weight: bolder;
}

.is-lighter {
  font-weight: lighter;
}

a {
  color: $white;
  text-decoration: underline;
}

.no-decor {
  text-decoration: none;
}

a.button {
  text-decoration: none;
}

.has-background-white {
  & .title,
  & .subtitle,
  & .help {
    color: $black !important;
  }
}

.error-message {
  color: #e61818 !important;
  opacity: 1;
}

@media (min-width: 768px) {
  h1 {
    &.title {
      font-size: 2.8rem;
    }
  }
  h2 {
    &.title {
      font-size: 2.2rem;
    }
  }
}
