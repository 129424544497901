@page {
  size: A4 portrait;
  margin: 10mm 10mm 10mm 10mm;
}

@media print {
  html, body {
      height: 99%;    
      background: white !important;

  }
}

.printed-doc {

  & body {
    background: white !important;
    height: 100%;
  }
  & html {
    background: white !important;
  }
  & > div > * {
    background: white;
  }
  & .avoid-page-break {
    page-break-inside: avoid;
  }
  & h2 {
    font-size: 18px;
    color: black;
  }

  & h3 {
    font-size: 12px;
    color: black;
  }

  & h4 {
    font-size: 11px;
    margin: 2px;
    color: black;
  }

  & label {
    font-size: 14px;
  }

  & .separator {
    margin-top: 20px;
    margin-bottom: 20px;
    background: #5b7682;
    height: 10px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
  }

  & .box {
    page-break-inside: avoid;
    border: 2px solid black !important;
    border-radius: 5px;
    margin-bottom: 20px;
    background: #5b7682;
    height: 100px;
    padding: 6px;
    & h2,
    h3,
    h4 {
      color: white;
    }
  }

  & .prodotti {
    padding: 8px;
    border-radius: 5px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    min-height: 400px;
  }

  & .header-prodotti {
    background: #5b7682;
    margin: -8px;
    margin-top: 4px;
    padding-left: 8px !important;
    padding-right: 8px !important;

    & h2,
    h3,
    h4,
    .label {
      color: white;
    }
  }
}

.generic-doc {
  & .label:not(:last-child) {
    margin-bottom: 0px !important;
  }
}

.produzione-doc {
  & .prodotti {
    padding: 8px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    height: 100%;
  }

  & .column-rows:nth-child(even) {
    background-color: #80808026;
  }

  & h2,
  h3,
  h4,
  .label,
  .help {
    color: black;
    padding-left: 4px;
    padding-right: 4px;
  }

  & h3 {
    font-size: 24px;
  }

  & .label {
    font-size: 14px;
  }
}
